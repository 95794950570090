<nav class="row navbar navbar-light navbar-full" pureTabGroup [selectedTab]="selectedTab">
    <div
        *ngFor="let category of categorizedSubmenuItems; let first = first"
        class="category-container"
        [ngClass]="{ active: isCategoryActive(category), inactive: !isCategoryActive(category) }"
    >
        <div class="category-title" [routerLink]="category.submenuItems[0]?.url" blurOnClick>
            {{ category.categoryTitle | uppercase }}
        </div>

        <ul class="nav navbar-nav">
            <li
                *ngFor="let submenuItem of category.submenuItems"
                class="nav-item categorized-nav-item"
                [ngClass]="{ active: isActive(submenuItem) }"
                [pureTabName]="submenuItem.url"
            >
                <a class="nav-link" [routerLink]="submenuItem.url" blurOnClick>
                    {{ submenuItem.title }}
                    <hive-badge
                        *ngIf="submenuItem.pillText"
                        [emphasis]="submenuItem.pillEmphasis || 'info'"
                        [label]="submenuItem.pillText"
                        [hiveTooltip]="submenuItem.pillTooltip"
                    />
                </a>
            </li>
        </ul>

        <div class="category-separator" [class.first-separator]="first"></div>
    </div>
</nav>
