import { NotificationPopupComponent } from '@pure/notification-center';
import { Angulartics2Module } from 'angulartics2';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
    AppSwitcherContentsComponent,
    UserProfileButtonComponent,
    OrgSwitcherComponent,
} from '@pure/pure1-ui-platform-angular';
import { NotificationCenterModule } from '../notification-center/notification-center.module';

import { UIModule } from '../ui/ui.module';
import { UserButtonComponent } from './page-topbar/user-button/user-button.component';
import { PageWrapperComponent } from './page-wrapper/page-wrapper.component';
import { ExportModule } from '../export/export.module';
import { NpsSurveyModule } from '../nps-survey/nps-survey.module';
import { SupportModule } from '../support/support.module';
import { TouModule } from '../tou/tou.module';
import { PageSidePanelComponent } from './page-side-panel/page-side-panel.component';
import { OrdersActivityPanelComponent } from './page-side-panel/orders-activity-panel/orders-activity-panel.component';
import { PurchaseOrderQuoteComponent } from './page-side-panel/orders-activity-panel/purchase-order-quote/purchase-order-quote.component';
import { PurchaseOrderUploadFormComponent } from './page-side-panel/orders-activity-panel/purchase-order-upload-form/purchase-order-upload-form.component';
import { EvergreenActivityPanelComponent } from './page-side-panel/evergreen-activity-panel/evergreen-activity-panel.component';
import { OtherArraysInfoComponent } from './page-side-panel/evergreen-activity-panel/other-arrays-info/other-arrays-info.component';
import {
    AppNavigationModule,
    BadgeModule,
    PageBaseLayoutModule,
    RootDirective,
    SeparatorComponent,
    SvgDirective,
    TooltipModule,
} from '@pure/hive';
import { EmptyPageComponent } from './empty-page/empty-page.component';
import { AuthorizerModule } from '@pure/authz-authorizer';
import { PageRoutingModule } from './page-routing.module';
import { NavMenuIdPipe } from './pipes/nav-menu-id.pipe';
import { CopilotOnboardingModalComponent } from '../copilot/copilot-onboarding-modal/copilot-onboarding-modal.component';

@NgModule({
    imports: [
        Angulartics2Module,
        AppSwitcherContentsComponent,
        AuthorizerModule,
        CommonModule,
        FormsModule,
        NgbModule,
        UIModule,
        ExportModule,
        NpsSurveyModule,
        SupportModule,
        TouModule,
        TooltipModule,
        BadgeModule,
        NotificationCenterModule,
        SvgDirective,
        PageBaseLayoutModule,
        PageRoutingModule,
        RootDirective,
        UserProfileButtonComponent,
        NotificationPopupComponent,
        AppNavigationModule,
        CopilotOnboardingModalComponent,
        SeparatorComponent,
        OrgSwitcherComponent,
    ],
    declarations: [
        EvergreenActivityPanelComponent,
        NavMenuIdPipe,
        OrdersActivityPanelComponent,
        OtherArraysInfoComponent,
        PageSidePanelComponent,
        PageWrapperComponent,
        PurchaseOrderQuoteComponent,
        PurchaseOrderUploadFormComponent,
        UserButtonComponent,
        EmptyPageComponent,
    ],
})
export class PageModule {}
