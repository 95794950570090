import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { smartTimer } from '@pstg/smart-timer';
import { AuthorizationServiceResolver } from '@pure/authz-authorizer';
import {
    AlertServiceV2,
    CachedCurrentUserService,
    DataPage,
    DpaSafeMode,
    DpaSafeModeService,
    FeatureFlagDxpService,
    ResourceStatus,
    ServiceCatalogQuote,
    ServiceCatalogQuoteServiceV4,
    UnifiedArray,
} from '@pure1/data';
import { Angulartics2 } from 'angulartics2';
import { cloneDeep, isEqual, orderBy } from 'lodash';
import moment from 'moment';
import { BehaviorSubject, combineLatest, firstValueFrom, from, Observable, of, Subject, zip } from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    exhaustMap,
    map,
    startWith,
    switchMap,
    take,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { AssetSortRules, SortRule, SortRules } from '../../arrays/services/asset-sort-rules.service';
import { FeatureNames, PureArray } from '../../model/model';

import {
    addSort,
    ARRAY_NAME_KEY,
    AVAILABLE_PREINSTALLED_CAPACITY,
    CHASSIS_SERIAL_NUMBER,
    CITY_KEY,
    CONTRACT_STATUS_CODE_KEY,
    COUNTRY_KEY,
    FQDN,
    GLOBAL_FILTER,
    HAS_END_OF_LIFE_HARDWARE,
    HOST_HOSTNAME_KEY,
    HOST_IQN_WWN_KEY,
    IS_PAAS_ARRAY,
    LOCAL_BAR_ARRAYS,
    MODEL_KEY,
    ORGNAME_KEY,
    PROTOCOL_KEY,
    removeAllSorts,
    SUBSCRIPTION_LICENSE_LICENSE_TYPE,
    SUBSCRIPTION_LICENSE_NAME,
    SUBSCRIPTION_LICENSE_SUBSCRIPTION_NAME,
    TAGS_KEY,
    TARGET_IQN_WWN_KEY,
    VERSION_KEY,
} from '../../redux/actions';
import { NgRedux } from '../../redux/ng-redux.service';
import { IState } from '../../redux/pure-redux.service';
import { UrlReaderWriter } from '../../redux/url-reader-writer';
import { UrlService } from '../../redux/url.service';
import { getActiveFilters } from '../../redux/utils';
import { OrdersUpdateService } from '../../service-catalog/services/orders-update.service';
import { ArraysManager } from '../../services/arrays-manager.service';
import { StorageService } from '../../services/storage.service';
import { ISubmenuBarItem } from '../../ui/components/submenu-bar/submenu-bar-base.component';
import { recursiveListItems } from '../../utils/collection';
import { DpaSafeModeUtils, SAFEMODE_REFRESH_INTERVAL } from '../../utils/dpa-safe-mode-utils';
import { createAlertFromResourceV2 } from '../../utils/legacy-alerts';
import PureUtils from '../../utils/pure_utils';
import {
    DO_NOT_SHOW_INTRODUCING_IDENTITY_CENTER_MODAL,
    IntroducingIdentityCenterComponent,
} from '../introducing-identity-center/introducing-identity-center.component';

//Do not add appliance id. It will override item.id in <unified-array.ts>
const REFRESH_ARRAY_FIELDS = [
    'org_id',
    'last_updated',
    'is_current',
    'array_id',
    'contract_status',
    'domain',
    'gui_url',
    'hostname',
    'message_counts',
    'purearray_monitor',
    'purearray_list_space',
    'model',
    'os',
    'purity_version',
    'protocol',
    'end_of_life_hardware',
    'has_end_of_life_hardware',
    'chassis_serial_number',
    'capacity_expandable',
    'support_expiration_date',
].join(',');
const ARRAYS_REFRESH_INTERVAL = moment.duration(30, 'seconds').asMilliseconds();

interface IViewState {
    filters: Object;
}

interface IViewTab {
    id: string;
    url: string;
    svg: string;
    title: string;
}

/**
 * The appliances pages often use the same code/components, needing just very slight variations.
 * In the case of those components, we pass through a "view mode" which tells us what type we're working with.
 */
export type AppliancesViewMode = 'array';

@Component({
    selector: 'appliances-view',
    templateUrl: 'appliances-view.component.html',
})
export class AppliancesViewComponent implements OnInit, OnDestroy {
    readonly mode: AppliancesViewMode = 'array'; // NOTE: Since we no longer have StorReduce, this is just a fixed value now

    readonly ResourceStatus = ResourceStatus;

    readonly defaultTabs: IViewTab[] = [
        {
            id: 'view-detailed',
            url: '/fleet/appliances/arrays/card',
            svg: 'card-view.svg',
            title: 'Card View',
        },
        {
            id: 'view-expanded',
            url: '/fleet/appliances/arrays/expanded',
            svg: 'expanded-card-view.svg',
            title: 'Expanded Card View',
        },
        {
            id: 'view-list',
            url: '/fleet/appliances/arrays/list',
            svg: 'list-view.svg',
            title: 'List View',
        },
    ];
    readonly submenuItems: ISubmenuBarItem[] = [{ title: 'Arrays', url: '/fleet/appliances/arrays' }];

    localBarId: 'arrays';
    loading = true;
    filteredArrays: UnifiedArray[] = [];
    filteredArraysCount = null;
    views$: Observable<IViewTab[]> = combineLatest([
        this.addTabAuthorized('PURE1:read:mapview', {
            id: 'view-map',
            url: '/fleet/appliances/arrays/mapview',
            svg: 'map-view.svg',
            title: 'Map View',
        }),
        this.addTabAuthorized('PURE1:read:tags', {
            id: 'view-tags',
            url: '/fleet/appliances/arrays/tags',
            svg: 'tags-view.svg',
            title: 'Tags',
        }),
    ]).pipe(
        map(conditionalTabs => [...this.defaultTabs, ...conditionalTabs.filter(Boolean)]),
        startWith(this.defaultTabs),
    );
    viewState: IViewState = {
        filters: {},
    };

    sortRule: SortRule<any>;
    sortRules: SortRules<any>;
    sortDesc = false;
    allOrders: ServiceCatalogQuote[] = [];
    alertMap: Map<string, IAlert[]>;
    showWarningCardForOutOfSupportAppliance = true;

    private refreshArrays$ = new BehaviorSubject<void>(null);
    private unregisterRW: () => void;
    private unsubscribeFromRedux: Function;
    private readonly destroy$ = new Subject<void>();

    constructor(
        private ngRedux: NgRedux<IState>,
        private alertService: AlertServiceV2,
        private dpaSafeModeService: DpaSafeModeService,
        private arraysManager: ArraysManager,
        private angulartics2: Angulartics2,
        private assetSortRules: AssetSortRules,
        private featureFlagDxpService: FeatureFlagDxpService,
        private ordersUpdateService: OrdersUpdateService,
        private serviceCatalogQuoteService: ServiceCatalogQuoteServiceV4,
        private url: UrlService,
        private authorizationServiceResolver: AuthorizationServiceResolver,
        private router: Router,
        private modalService: NgbModal,
        private storageService: StorageService,
        private currentUserService: CachedCurrentUserService,
    ) {}

    ngOnInit(): void {
        this.angulartics2.eventTrack.next({
            action: 'Browser display width',
            properties: { label: this.getDisplayWidthBucket(), category: 'Event' },
        });

        this.angulartics2.eventTrack.next({
            action: 'Browser display height',
            properties: { label: this.getDisplayHeightBucket(), category: 'Event' },
        });

        if (this.mode === 'array') {
            this.localBarId = LOCAL_BAR_ARRAYS;
            this.assetSortRules
                .getArraySortWithFlags(() => this.alertMap)
                .subscribe(rules => {
                    this.sortRules = rules;
                    this.sortRule = AssetSortRules.getFirstSelectableRuleForArraySort(this.sortRules);

                    // connect ngRedux to store
                    this.unsubscribeFromRedux = this.ngRedux.subscribe(() => this.handleRedux());
                    this.unregisterRW = this.url.register(new AppliancesUrlReaderWriter(this.localBarId), true);
                    // Apply state and perform initial update
                    this.handleRedux(true);
                });
        }

        // Periodic update
        const arrays$ = smartTimer(0, ARRAYS_REFRESH_INTERVAL).pipe(
            tap(() => (this.loading = true)),
            exhaustMap(() =>
                from(
                    this.arraysManager.getPureArrays(
                        REFRESH_ARRAY_FIELDS,
                        this.viewState.filters,
                        'include_arrays_in_courtesy_period',
                    ),
                ).pipe(take(1)),
            ),
        );

        // Ideally, we should tie both arrays and alerts to refreshArrays$.
        // However, alerts always takes >2.5X the time of arrays, so it's hard to do without ruining performance
        const getArrays$ = this.refreshArrays$.pipe(
            switchMap(() => arrays$),
            catchError(error => {
                const errorMsg = error && (error.data?.message || error.statusText);
                console.error('Error updating arrays: ' + errorMsg);
                return of(null);
            }),
        );
        /*
         * We assume that we have full SafeMode information from the beginning.
         * No filters are needed, we need this data only for enriching the already pre-filtered data
         * and therefore we do not need to refresh when filters / sorting is updated
         */
        const getSafeMode$ = smartTimer(0, SAFEMODE_REFRESH_INTERVAL).pipe(
            tap(() => (this.loading = true)),
            exhaustMap(() =>
                this.dpaSafeModeService.list().pipe(
                    take(1),
                    catchError(error => {
                        const errorMsg = error && (error.data?.message || error.statusText);
                        console.error('Error updating safemode: ' + errorMsg);
                        return of<DataPage<DpaSafeMode>>(null);
                    }),
                ),
            ),
        );

        const getAlerts$ = smartTimer(0, moment.duration(30, 'seconds').asMilliseconds()).pipe(
            exhaustMap(() =>
                recursiveListItems(
                    this.alertService,
                    { filter: { status: `status=('open')` } },
                    'body.updated',
                    alert => alert.id,
                ),
            ),
            take(1),
            map(
                result => result.map(resource => createAlertFromResourceV2(resource)).filter(alert => alert != null), // Filter out invalid alerts
            ),
            catchError(error => {
                const errorMsg = error && (error.data?.message || error.statusText);
                console.error('Error updating alerts: ' + errorMsg);
                return of(null);
            }),
        );

        const orderQuotes$ = this.ordersUpdateService.refreshQuotes$.pipe(
            tap(() => (this.loading = true)),
            switchMap(() => {
                return this.serviceCatalogQuoteService.list({}).pipe(
                    take(1),
                    map(result => {
                        return result.response.slice().sort();
                    }),
                    catchError(err => {
                        console.error('serviceCatalogQuoteService.list() failed', err);
                        return of([] as ServiceCatalogQuote[]);
                    }),
                    distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
                );
            }),
            takeUntil(this.destroy$),
        );

        combineLatest([getArrays$, getAlerts$, orderQuotes$, getSafeMode$])
            .pipe(takeUntil(this.destroy$))
            .subscribe(([arrays, alerts, orderQuotes, safeMode]) => {
                if (arrays === null || alerts === null) {
                    // something failed to refresh, keep the old data
                    this.loading = false;
                    return;
                }

                this.filteredArrays = (arrays || []).filter(item => {
                    switch (this.mode) {
                        case 'array':
                            return item instanceof PureArray;
                        default:
                            throw new Error('Invalid mode: ' + this.mode);
                    }
                });

                this.filteredArrays = DpaSafeModeUtils.getArraysWithDpaSafeMode(
                    this.filteredArrays,
                    safeMode?.response || [],
                );
                this.filteredArraysCount = this.filteredArrays.length;

                this.alertMap = PureUtils.createMapGroupByProperty(alerts, 'arrayId');
                this.allOrders = cloneDeep(orderQuotes);
                this.updateSorting();
                this.loading = false;
            });

        // Determine visibility of warning card for out of support appliances
        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.VIEW_SUPPORT_EXPIRED_APPLIANCE_WARNING_CARD)
            .subscribe(feature => {
                this.showWarningCardForOutOfSupportAppliance = feature?.enabled;
            });

        this.maybeOpenIntroducingIdentityCenterModal();
    }

    ngOnDestroy(): void {
        this.unsubscribeFromRedux?.();
        this.unregisterRW?.();

        this.refreshArrays$.complete();
        this.destroy$.next();
        this.destroy$.complete();
    }

    isRoute(url: string): boolean {
        return this.router.isActive(url, {
            paths: 'exact',
            queryParams: 'ignored',
            fragment: 'ignored',
            matrixParams: 'ignored',
        });
    }

    hasNoData(): boolean {
        return !this.loading && this.filteredArrays.length === 0;
    }

    clickSortOrder(): void {
        this.sortDesc = !this.sortDesc;
        this.ngRedux.dispatch([
            removeAllSorts(this.localBarId),
            addSort(this.localBarId, this.sortRule.id + (this.sortDesc ? '-' : '')),
        ]);
    }

    changeSortRule(rule: SortRule<any>): void {
        this.sortRule = rule;

        this.ngRedux.dispatch([
            removeAllSorts(this.localBarId),
            addSort(this.localBarId, this.sortRule.id + (this.sortDesc ? '-' : '')),
        ]);
    }

    handleRedux(forceUpdateArrays = false): void {
        const state = this.ngRedux.getState();

        // Filter
        const activeFilters = getActiveFilters(state.filters[GLOBAL_FILTER], state.filters[this.localBarId]);
        const newFilters = Object.assign({}, this.viewState.filters, {
            org_name: activeFilters[ORGNAME_KEY] ? Object.keys(activeFilters[ORGNAME_KEY] || []) : undefined,
            city: activeFilters[CITY_KEY] ? Object.keys(activeFilters[CITY_KEY] || []) : undefined,
            country: activeFilters[COUNTRY_KEY] ? Object.keys(activeFilters[COUNTRY_KEY] || []) : undefined,
            fqdn: activeFilters[FQDN] ? Object.keys(activeFilters[FQDN] || []) : undefined,
            array_name: activeFilters[ARRAY_NAME_KEY] ? Object.keys(activeFilters[ARRAY_NAME_KEY]) : undefined,
            model: activeFilters[MODEL_KEY] ? Object.keys(activeFilters[MODEL_KEY] || []) : undefined,
            version: activeFilters[VERSION_KEY] ? Object.keys(activeFilters[VERSION_KEY] || []) : undefined,
            protocol_list: activeFilters[PROTOCOL_KEY] ? Object.keys(activeFilters[PROTOCOL_KEY] || []) : undefined,
            host_iqn_wwn_list: activeFilters[HOST_IQN_WWN_KEY]
                ? Object.keys(activeFilters[HOST_IQN_WWN_KEY] || [])
                : undefined,
            target_iqn_wwn_list: activeFilters[TARGET_IQN_WWN_KEY]
                ? Object.keys(activeFilters[TARGET_IQN_WWN_KEY] || [])
                : undefined,
            host_hostname_list: activeFilters[HOST_HOSTNAME_KEY]
                ? Object.keys(activeFilters[HOST_HOSTNAME_KEY] || [])
                : undefined,
            is_paas_array: activeFilters[IS_PAAS_ARRAY] ? Object.keys(activeFilters[IS_PAAS_ARRAY] || []) : undefined,
            contract_status_code: activeFilters[CONTRACT_STATUS_CODE_KEY]
                ? Object.keys(activeFilters[CONTRACT_STATUS_CODE_KEY] || [])
                : undefined,
            'subscription_license.name': activeFilters[SUBSCRIPTION_LICENSE_NAME]
                ? Object.keys(activeFilters[SUBSCRIPTION_LICENSE_NAME] || [])
                : undefined,
            'subscription_license.license_type': activeFilters[SUBSCRIPTION_LICENSE_LICENSE_TYPE]
                ? Object.keys(activeFilters[SUBSCRIPTION_LICENSE_LICENSE_TYPE] || [])
                : undefined,
            'subscription_license.subscription.name': activeFilters[SUBSCRIPTION_LICENSE_SUBSCRIPTION_NAME]
                ? Object.keys(activeFilters[SUBSCRIPTION_LICENSE_SUBSCRIPTION_NAME] || [])
                : undefined,
            has_end_of_life_hardware: activeFilters[HAS_END_OF_LIFE_HARDWARE]
                ? Object.keys(activeFilters[HAS_END_OF_LIFE_HARDWARE] || [])
                : undefined,
            capacity_expandable: activeFilters[AVAILABLE_PREINSTALLED_CAPACITY]
                ? Object.keys(activeFilters[AVAILABLE_PREINSTALLED_CAPACITY] || [])
                : undefined,
            chassis_serial_number: activeFilters[CHASSIS_SERIAL_NUMBER]
                ? Object.keys(activeFilters[CHASSIS_SERIAL_NUMBER] || [])
                : undefined,
            tags: activeFilters[TAGS_KEY] || undefined,
        });

        const filtersChanged = !isEqual(this.viewState.filters, newFilters);
        if (filtersChanged) {
            this.viewState.filters = newFilters;
        }

        // Sort
        const sortState = (state.sorts[this.localBarId] || [])[0] || '';
        const sortKey = sortState.endsWith('-') ? sortState.substr(0, sortState.length - 1) : sortState; // Remove minus for desc
        const sortDesc = sortState.endsWith('-');

        this.sortRule =
            this.sortRules.rules.find(rule => rule.id === sortKey) ||
            AssetSortRules.getFirstSelectableRuleForArraySort(this.sortRules);
        this.sortDesc = sortDesc;

        if (filtersChanged || forceUpdateArrays) {
            this.refreshArrays$.next(); // Do a full refresh
        } else {
            this.updateSorting(); // Don't refetch, just sort
        }
    }

    private addTabAuthorized(permission: string, tab: IViewTab) {
        return this.authorizationServiceResolver.getDefaultService().pipe(
            switchMap(authz => authz.hasPermission(permission)),
            map(authorized => (authorized ? tab : void 0)),
        );
    }

    private updateSorting(): void {
        if (!this.sortRule) {
            return;
        }

        const sortFuncs = (this.sortRule.valueFuncs || []).concat(this.sortRules.finalSort);
        const order = this.sortDesc ? 'desc' : 'asc';

        this.filteredArrays = orderBy(this.filteredArrays || [], sortFuncs, Array(sortFuncs.length).fill(order));
    }

    private getDisplayWidthBucket(): string {
        const WIDTH_BUCKETS = [0, 600, 1000, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 2000, 3000, 4000, 8000, 100000];

        const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

        const bucket = WIDTH_BUCKETS.findIndex(bucket => w < bucket);

        if (bucket < 1) {
            return `Width measurement error`;
        }

        return `Width: ${WIDTH_BUCKETS[bucket - 1]} - ${WIDTH_BUCKETS[bucket]}`;
    }

    private getDisplayHeightBucket(): string {
        const HEIGHT_BUCKETS = [0, 300, 500, 600, 700, 800, 900, 1000, 1200, 1500, 2000, 3000, 4000, 100000];

        const h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

        const bucket = HEIGHT_BUCKETS.findIndex(bucket => h < bucket);

        if (bucket < 1) {
            return `Height measurement error`;
        }

        return `Height: ${HEIGHT_BUCKETS[bucket - 1]} - ${HEIGHT_BUCKETS[bucket]}`;
    }

    /**
     * Open the introducing identity center modal if the user has not dismissed it previously and the feature is enabled
     */
    protected async maybeOpenIntroducingIdentityCenterModal() {
        /**
         * Show dialog anywhere except the map view
         */
        if (this.isRoute('/fleet/appliances/arrays/mapview')) {
            return;
        }

        const currentUser = await firstValueFrom(this.currentUserService.get());

        /**
         * Do not show the dialog if the user is impersonating
         */
        if (currentUser.impersonating) {
            return;
        }

        /**
         * Check if user can read identity management entities
         * If the user does not have the permission, do not show the dialog
         */
        const isAuthorized = await firstValueFrom(
            this.authorizationServiceResolver
                .getDefaultService()
                .pipe(
                    switchMap(authz =>
                        authz.hasAnyPermission(['PURE1:read:apikey', 'PURE1:read:sso', 'PURE1:read:users']),
                    ),
                ),
        );
        if (!isAuthorized) {
            return;
        }

        await this.openIntroducingIdentityCenterModal();
    }

    protected async openIntroducingIdentityCenterModal() {
        /**
         * Check if the dialog has been dismissed previously
         */
        if (this.storageService.get(DO_NOT_SHOW_INTRODUCING_IDENTITY_CENTER_MODAL)) {
            return;
        }
        /**
         * Open the dialog
         */
        const modalRef = this.modalService.open(IntroducingIdentityCenterComponent, {
            windowClass: 'introducing-identity-center-modal-window',
            modalDialogClass: 'hive-root ui-next',
            backdrop: 'static',
        });

        /**
         * Wait for the dialog to close
         */
        const doNotShowAgain = await firstValueFrom(from(modalRef.result));

        /**
         * If the user has selected "Do not show again", store the setting
         */
        if (doNotShowAgain) {
            this.storageService.set(DO_NOT_SHOW_INTRODUCING_IDENTITY_CENTER_MODAL, true);
        }
    }
}

class AppliancesUrlReaderWriter extends UrlReaderWriter {
    path = /^\/fleet\/appliances/;

    constructor(public localBarId: string) {
        super();
    }
}
