<submenu-bar [submenuItems]="submenuItems"></submenu-bar>

<div class="p-arrays" id="p-arrays" *ngIf="(views$ | async) && sortRules">
    <pureui-spinner class="p-loading" [pureuiShow]="loading"></pureui-spinner>

    <!-- banners -->
    <banners
        class="banner-shift-down-reduce-width"
        [ngClass]="{ 'map-view-margin': isRoute('/fleet/appliances/arrays/mapview') }"
    ></banners>
    <eol-warning-banner
        class="banner-shift-down-reduce-width"
        *ngIf="!isRoute('/fleet/appliances/arrays/mapview')"
    ></eol-warning-banner>

    <div class="col-xs-12 btn-toolbar" [ngClass]="{ 'map-visible': isRoute('/fleet/appliances/arrays/mapview') }">
        <!-- Global search bar -->
        <gfb
            *ngIf="localBarId === 'arrays'"
            barId="arrays"
            [entities]="['arrays', 'subscription license']"
            [supportStatusFilterOption]="'include_arrays_in_courtesy_period'"
            defaultEntity="arrays"
            defaultKey="array_name"
            class="btn-group flex-btn-group flex-btn-group-first"
        ></gfb>

        <!-- Sorting -->
        <div class="pull-right medium-margin-left">
            <div class="flex-btn-group sort-container">
                <div class="input-group">
                    <ng-container *ngIf="isRoute('/fleet/appliances/arrays/mapview'); else sortSelector">
                        <div id="search-bar">
                            <input id="map-search" />
                            <pureui-svg
                                class="search-icon"
                                [svg]="'magnifying-glass-icon.svg'"
                                [height]="18"
                            ></pureui-svg>
                        </div>
                    </ng-container>

                    <ng-template #sortSelector>
                        <select
                            class="custom-select"
                            style="width: auto !important"
                            [ngModel]="sortRule"
                            (ngModelChange)="changeSortRule($event)"
                        >
                            <ng-container *ngFor="let rule of sortRules.rules">
                                <ng-container *ngIf="rule.isOptGroup; else selectOptionTemplate">
                                    <optgroup *ngIf="rule.label !== 'Appliance'" label=""></optgroup>
                                    <optgroup label="{{ rule.label }}"></optgroup>
                                </ng-container>
                                <ng-template #selectOptionTemplate>
                                    <option [ngValue]="rule">{{ rule.label }}</option>
                                </ng-template>
                            </ng-container>
                        </select>

                        <span class="input-group-btn">
                            <button
                                class="btn btn-primary with-action-icon active"
                                (click)="clickSortOrder()"
                                blurOnClick
                            >
                                <pureui-svg
                                    class="pstg-action-icon"
                                    *ngIf="sortDesc"
                                    svg="sort-desc.svg"
                                    height="16"
                                ></pureui-svg>
                                <pureui-svg
                                    class="pstg-action-icon"
                                    *ngIf="!sortDesc"
                                    svg="sort-asc.svg"
                                    height="16"
                                ></pureui-svg>
                            </button>
                        </span>
                    </ng-template>
                </div>
            </div>
        </div>

        <!-- Array total count -->
        <div class="pull-right medium-margin-left total-count">
            <span *ngIf="filteredArraysCount != null"> Showing {{ filteredArraysCount }} </span>
            <span *ngIf="filteredArraysCount == null" class="fa fa-spin fa-gear"> </span>
        </div>

        <!-- View selector -->
        <div class="btn-group flex-btn-group flex-btn-group-last">
            <label
                *ngFor="let view of views$ | async"
                blurOnClick
                class="btn btn-primary with-action-icon legacy-uib-btn-radio {{ view.id }}"
                [ngClass]="{ active: isRoute(view.url) }"
                [routerLink]="view.url"
                queryParamsHandling="preserve"
                [ngbTooltip]="view.title"
                placement="top"
                container="body"
                angulartics2On="click"
                angularticsAction="Arrays - View selected"
                [angularticsLabel]="view.title"
            >
                <pureui-svg class="pstg-action-icon" [svg]="view.svg" height="16"></pureui-svg>
            </label>
        </div>
    </div>

    <banner-carousel *ngIf="!isRoute('/fleet/appliances/arrays/mapview')"></banner-carousel>

    <div class="arrays-view col-xs-12">
        <arrays-card-view
            *ngIf="isRoute('/fleet/appliances/arrays/card')"
            [filteredArrays]="filteredArrays"
            [alertMap]="alertMap"
            [allOrders]="allOrders"
            [isLoading]="loading"
            [showWarningCardForOutOfSupportAppliance]="showWarningCardForOutOfSupportAppliance"
            [mode]="mode"
        >
        </arrays-card-view>

        <arrays-expanded-card-view
            *ngIf="isRoute('/fleet/appliances/arrays/expanded')"
            [filteredArrays]="filteredArrays"
            [alertMap]="alertMap"
            [allOrders]="allOrders"
            [isLoading]="loading"
            [showWarningCardForOutOfSupportAppliance]="showWarningCardForOutOfSupportAppliance"
            [mode]="mode"
        >
        </arrays-expanded-card-view>

        <arrays-list-view
            *ngIf="isRoute('/fleet/appliances/arrays/list')"
            [filteredArrays]="filteredArrays"
            [alertMap]="alertMap"
            [allOrders]="allOrders"
            [isLoading]="loading"
            [showWarningCardForOutOfSupportAppliance]="showWarningCardForOutOfSupportAppliance"
            [mode]="mode"
        >
        </arrays-list-view>

        <arrays-map-view *ngIf="isRoute('/fleet/appliances/arrays/mapview')"></arrays-map-view>

        <arrays-tags-view
            *ngIf="isRoute('/fleet/appliances/arrays/tags')"
            [filteredArrays]="$any(filteredArrays)"
            [activeFilters]="$any(viewState.filters)"
            [mode]="mode"
            (arraysUpdated)="handleRedux(true)"
        >
        </arrays-tags-view>
    </div>

    <div class="no-array-data" *ngIf="hasNoData()"> Did not find any appliance data </div>
</div>
