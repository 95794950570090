import { Component, Input, OnInit } from '@angular/core';
import { UnifiedArray } from '@pure1/data';
import moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { clone, cloneDeep } from 'lodash';
import { Moment } from 'moment/moment';

//the EOSC reinstatement period
//which means when the appliance is out of contract, it will still show up on appliance page for the next 365 days after contract_expiration_date.
const REINSTATEMENT_PERIOD = 365;

@Component({
    selector: 'appliance-out-of-contract-template',
    templateUrl: 'appliance-out-of-contract-template.component.html',
})
export class ApplianceOutOfContractTemplateComponent implements OnInit {
    @Input() readonly selectedArray: UnifiedArray;

    endOfReinstatementDate: moment.Moment;
    isSubmitted = false;
    isValidToRequest = false;
    theDaysLeftUntilRemoval: number;

    readonly TODAY = moment.utc();

    constructor(public modalService: NgbModal) {}

    ngOnInit(): void {
        //Adding the counter for the EOSC reinstatement period.
        this.endOfReinstatementDate = this.selectedArray?.contract_expiration
            ?.clone()
            ?.add(REINSTATEMENT_PERIOD, 'days');
        this.isValidToRequest =
            !this.selectedArray.contract_last_renew_requested ||
            moment(this.selectedArray.contract_last_renew_requested).isBefore(moment.utc().subtract(7, 'days'));
        this.theDaysLeftUntilRemoval = this.endOfReinstatementDate.diff(this.TODAY, 'days');
    }

    submitChangesComplete(): void {
        this.isSubmitted = true;
    }
}
