<nav class="row navbar navbar-light navbar-full">
    <ul class="nav navbar-nav" pureTabGroup [selectedTab]="selectedTab">
        <li
            *ngFor="let submenuItem of submenuItems"
            class="nav-item"
            [ngClass]="{ active: isActive(submenuItem) }"
            [pureTabName]="submenuItem.url"
        >
            <a
                *ngIf="submenuItem.url"
                class="nav-link"
                [routerLink]="submenuItem.url"
                [attr.test-id]="submenuItem.testId"
                blurOnClick
            >
                {{ submenuItem.title }}
                <hive-badge
                    *ngIf="submenuItem.pillText"
                    [emphasis]="submenuItem.pillEmphasis || 'info'"
                    [label]="submenuItem.pillText"
                    [hiveTooltip]="submenuItem.pillTooltip"
                />
            </a>
        </li>
    </ul>
</nav>
