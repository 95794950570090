import { Angulartics2 } from 'angulartics2';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { map, take } from 'rxjs/operators';

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ArrayTagService, FilterParams, Resource, Tag, CachedCurrentUserService, CurrentUser } from '@pure1/data';
import { AuthorizationService } from '@pure/authz-authorizer';

import { ManageTagsModalComponent } from '../manage-tags/manage-tags-modal.component';
import { MultiResourceTagBuilder } from '../multi-resource-tag';
import { TagActions } from '../tag-change-summary/tag-change-summary.component';
import { TagChange } from '../tag-change';
import { AppliancesViewMode } from '../../arrays/appliances-view/appliances-view.component';

export class TagWithChangedFlag extends Tag {
    readonly changed: boolean;

    constructor(tag: Tag, changed = false) {
        super(tag);
        this.changed = changed;
    }
}

@Component({
    selector: 'arrays-tags-view',
    templateUrl: 'arrays-tags-view.component.html',
})
export class ArraysTagsViewComponent implements OnInit, OnChanges, OnDestroy {
    @Input() readonly filteredArrays: PureArray[];
    @Input() readonly activeFilters: FilterParams<Tag>;
    @Input() readonly mode: AppliancesViewMode;
    @Output() readonly arraysUpdated: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('selectAllCheckbox', { static: true }) readonly selectAllCheckbox: ElementRef;

    tags: Tag[];
    resourceIdToTagMap = new Map<string, TagWithChangedFlag[]>();
    sortAscending = true;
    sortedArrays: PureArray[] = [];
    pageLoading = true;
    selectedArrays = new Map<string, Resource>();
    sortedSelectedArrays: Resource[] = [];
    editableTagOrganizationId: number;
    lastChange: TagChange[] = [];

    private tagSubscription: Subscription;

    constructor(
        private angulartics2: Angulartics2,
        private arrayTagService: ArrayTagService,
        private cachedCurrentUserService: CachedCurrentUserService,
        private modalService: NgbModal,
        private authorizationService: AuthorizationService,
    ) {}

    ngOnInit(): void {
        this.cachedCurrentUserService
            .get()
            .pipe(take(1))
            .subscribe((cu: CurrentUser) => {
                this.editableTagOrganizationId = cu.organization_id;
                if (this.resourceIdToTagMap) {
                    this.resourceIdToTagMap.forEach(tags => tags.sort((a: Tag, b: Tag) => this.tagCompare(a, b)));
                }
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.filteredArrays && this.filteredArrays != null) {
            this.sortedArrays = [...this.filteredArrays].sort((a, b) => {
                const asc = a.hostname.toLowerCase().localeCompare(b.hostname.toLowerCase());
                return this.sortAscending ? asc : -asc;
            });
            this.updateSelectAllCheckboxStatus();
            this.updateTags();
        }
        if (changes.activeFilters) {
            this.endChangeSummary();
        }
    }

    ngOnDestroy(): void {
        if (this.tagSubscription && !this.tagSubscription.closed) {
            this.tagSubscription.unsubscribe();
        }
    }

    open(event: MouseEvent, array: PureArray): void {
        // Don't toggle the select/unselect status for the row
        event.stopImmediatePropagation();
        event.preventDefault();
        this.endChangeSummary();

        this.angulartics2.eventTrack.next({
            action: 'Tagging - Edit one array',
            properties: { category: 'Action' },
        });
        this.openEdit([{ id: array.arrayId, name: array.hostname }]);
    }

    toggleSort(): void {
        this.sortAscending = !this.sortAscending;
        this.sortedArrays = [...this.sortedArrays].reverse();
        this.endChangeSummary();
    }

    trackByTagId(index: number, item: Tag): string {
        // We don't need resource id (array_id) because tags for each array are
        // already separated by row in the tags view
        return [item.key, item.value].join('__');
    }

    changeArraySelection(arrayId: string, hostname: string): void {
        this.isWriteTagsAllowed({ arrayId }).subscribe(isAllowed => {
            if (!isAllowed) {
                return;
            }

            if (this.selectedArrays.has(arrayId)) {
                this.selectedArrays.delete(arrayId);
            } else {
                this.selectedArrays.set(arrayId, { id: arrayId, name: hostname });
            }
            this.updateSortedSelectedArrays();
            this.updateSelectAllCheckboxStatus();
            this.endChangeSummary();
        });
    }

    onSelectionChange(arrays: Resource[]): void {
        this.selectedArrays = new Map(arrays.map(a => [a.id, a] as [string, Resource]));
        this.updateSortedSelectedArrays();
        this.updateSelectAllCheckboxStatus();
        this.endChangeSummary();
    }

    onRemoveSelection(array: Resource): void {
        this.changeArraySelection(array.id, array.name);
    }

    onClearSelection(): void {
        this.selectedArrays.clear();
        this.updateSortedSelectedArrays();
        const checkbox = this.selectAllCheckbox.nativeElement;
        checkbox.indeterminate = false;
        checkbox.checked = false;
        this.endChangeSummary();
    }

    selectAll(): void {
        const isAnyChecked = this.filteredArrays.some(array => this.selectedArrays.has(array.arrayId));
        const checkbox = this.selectAllCheckbox.nativeElement;
        if (isAnyChecked) {
            // Remove all the filtered arrays from selection (not the same as removing all selected!)
            this.filteredArrays.forEach(array => this.selectedArrays.delete(array.arrayId));
            checkbox.indeterminate = false;
            checkbox.checked = false;
            this.updateSortedSelectedArrays();
            this.endChangeSummary();
        } else {
            // Add all filtered arrays to the selection for where the user has write permissions
            forkJoin(
                this.filteredArrays.map(array =>
                    this.isWriteTagsAllowed(array).pipe(map(result => ({ array, isAllowed: result }))),
                ),
            ).subscribe(arrayResults => {
                arrayResults
                    .filter(result => result.isAllowed)
                    .map(result => result.array)
                    .forEach(array => {
                        this.selectedArrays.set(array.arrayId, { id: array.arrayId, name: array.hostname });
                    });

                checkbox.indeterminate = false;
                checkbox.checked = true;

                this.updateSortedSelectedArrays();
                this.endChangeSummary();
            });
        }
    }

    editSelected(): void {
        const label = this.selectedArrays.size === 1 ? '1' : this.selectedArrays.size <= 5 ? '2-5' : '6+';
        this.angulartics2.eventTrack.next({
            action: 'Tagging - Edit multiple arrays',
            properties: { category: 'Action', label },
        });
        this.openEdit(this.sortedSelectedArrays);
    }

    onRollbacked(result: TagActions): void {
        if (this.lastChange) {
            this.updateAffectedArrays(result, true);
            this.lastChange = [];
        }
    }

    endChangeSummary(): void {
        if (this.lastChange) {
            this.lastChange
                .filter(change => change.newValue)
                .forEach(change => {
                    const tags = this.resourceIdToTagMap.get(change.resourceId) || [];
                    const unchangedTags = [];
                    tags.forEach(tag => {
                        if (tag.changed) {
                            unchangedTags.push(new TagWithChangedFlag(tag, false));
                        } else {
                            unchangedTags.push(tag);
                        }
                    });
                    this.resourceIdToTagMap.set(change.resourceId, unchangedTags);
                });
            this.lastChange = [];
        }
    }

    private updateSortedSelectedArrays(): void {
        this.sortedSelectedArrays = Array.from(this.selectedArrays.values()).sort((e1, e2) => {
            const compareResult = e1.name.toLowerCase().localeCompare(e2.name.toLowerCase());
            return this.sortAscending ? compareResult : -compareResult;
        });
    }

    private updateAffectedArrays(result: TagActions, rolledBack = false): TagChange[] {
        const tagChanges: TagChange[] = [];
        // 1) organize both deleted and upserted tags per array
        const deletedArrayTags = new Map<string, string[]>();
        const resourceIds = new Set<string>();
        result.delete.forEach(tag => {
            tag.resourceIds.forEach(resourceId => {
                resourceIds.add(resourceId);
                if (!deletedArrayTags.has(resourceId)) {
                    deletedArrayTags.set(resourceId, []);
                }
                deletedArrayTags.get(resourceId).push(tag.uniqueCombinedKey);
            });
        });
        const upsertedArrayTags = new Map<string, Map<string, Tag>>();
        result.upsert.forEach(tag => {
            tag.resourceIds.forEach(resourceId => {
                resourceIds.add(resourceId);
                if (!upsertedArrayTags.has(resourceId)) {
                    upsertedArrayTags.set(resourceId, new Map<string, Tag>());
                }
                upsertedArrayTags.get(resourceId).set(tag.uniqueCombinedKey, tag);
            });
        });
        // 2) looping through affected arrays
        //     2.1 compare existing and changed, and generate tag change based on them
        //     2.3 update its tags for the view
        resourceIds.forEach(resourceId => {
            if (upsertedArrayTags.has(resourceId) || deletedArrayTags.has(resourceId)) {
                const tags = this.resourceIdToTagMap.get(resourceId) || [];
                const tagKeyMap: Map<string, TagWithChangedFlag> = new Map();
                tags.forEach(tag => tagKeyMap.set(tag.uniqueCombinedKey, tag));
                if (deletedArrayTags.has(resourceId)) {
                    deletedArrayTags.get(resourceId).forEach(uniqueCombinedKey => {
                        const previous = tagKeyMap.get(uniqueCombinedKey);
                        if (previous) {
                            tagKeyMap.delete(uniqueCombinedKey);
                            tagChanges.push(TagChange.deletedTag(previous, resourceId));
                        }
                    });
                }
                if (upsertedArrayTags.has(resourceId)) {
                    upsertedArrayTags.get(resourceId).forEach((tag, uniqueCombinedKey) => {
                        const previous = tagKeyMap.get(uniqueCombinedKey);
                        const current = new TagWithChangedFlag(tag, !rolledBack);
                        if (previous) {
                            tagChanges.push(TagChange.updatedTag(previous, resourceId, tag.key, tag.value));
                        } else {
                            tagChanges.push(TagChange.insertedTag(current, resourceId));
                        }
                        tagKeyMap.set(uniqueCombinedKey, current);
                    });
                }
                this.resourceIdToTagMap.set(
                    resourceId,
                    Array.from(tagKeyMap.values()).sort((a: Tag, b: Tag) => this.tagCompare(a, b)),
                );
            }
        });
        this.arraysUpdated.emit();
        return tagChanges;
    }

    private openEdit(arrays: Resource[]): void {
        // Don't open the modal if the tags are not loaded yet, because we would be binding an empty list to the modal
        if (this.pageLoading) {
            return;
        }

        const allTags = new Map<string, MultiResourceTagBuilder>();
        const count = arrays.length;
        arrays.forEach(array => {
            const tags = (this.resourceIdToTagMap.get(array.id) || [])
                .slice(0)
                .filter(tag => tag.tag_organization_id === this.editableTagOrganizationId);
            tags.forEach(tag => {
                const uniqueKey = this.getTagUniqueKey(tag);
                if (!allTags.has(uniqueKey)) {
                    allTags.set(uniqueKey, new MultiResourceTagBuilder(tag, count));
                }
                allTags.get(uniqueKey).add(tag.key, tag.value, array.id);
            });
        });
        const tags = Array.from(allTags.values()).map(builder => builder.build());
        const modalRef = this.modalService.open(ManageTagsModalComponent, { backdrop: 'static' });
        const manageTagsModal = modalRef.componentInstance as ManageTagsModalComponent;
        manageTagsModal.arrays = arrays;
        manageTagsModal.tags = tags;
        manageTagsModal.tagOrganizationId = this.editableTagOrganizationId;
        modalRef.result
            .then(result => {
                this.lastChange = this.updateAffectedArrays(result);
                this.selectedArrays.clear();
                this.updateSortedSelectedArrays();
            })
            .catch(reason => console.warn('Error returned from ManageTagsModal', reason));
    }

    private tagCompare(a: Tag, b: Tag): number {
        if (
            a.tag_organization_id === b.tag_organization_id ||
            (a.tag_organization_id !== this.editableTagOrganizationId &&
                b.tag_organization_id !== this.editableTagOrganizationId)
        ) {
            return a.key.toLowerCase().localeCompare(b.key.toLowerCase());
        } else {
            return a.tag_organization_id === this.editableTagOrganizationId ? -1 : 1;
        }
    }

    private updateSelectAllCheckboxStatus(): void {
        const checkedCount = this.filteredArrays.filter(array => this.selectedArrays.has(array.arrayId)).length;
        const checkbox = this.selectAllCheckbox.nativeElement;
        if (checkedCount === 0) {
            checkbox.checked = false;
            checkbox.indeterminate = false;
        } else if (checkedCount === this.filteredArrays.length) {
            checkbox.checked = true;
            checkbox.indeterminate = false;
        } else {
            checkbox.checked = false;
            checkbox.indeterminate = true;
        }
    }

    private updateTags(): void {
        if (this.filteredArrays && this.filteredArrays.length === 0) {
            return;
        }

        this.tagSubscription = this.arrayTagService
            .list({ filter: this.activeFilters })
            .pipe(take(1))
            .subscribe(pageData => {
                const lastChangedTagStrings = new Set<string>();
                if (this.lastChange) {
                    this.lastChange
                        .filter(change => change.newValue)
                        .forEach(change => lastChangedTagStrings.add(this.getTagString(change.toNewTag())));
                }
                this.pageLoading = false;
                this.tags = pageData.response;
                this.resourceIdToTagMap = new Map<string, TagWithChangedFlag[]>();
                this.tags.forEach(tag => {
                    if (!this.resourceIdToTagMap.has(tag.resource.id)) {
                        this.resourceIdToTagMap.set(tag.resource.id, []);
                    }
                    const changed = lastChangedTagStrings.has(this.getTagString(tag));
                    this.resourceIdToTagMap.get(tag.resource.id).push(new TagWithChangedFlag(tag, changed));
                });
                this.resourceIdToTagMap.forEach(tags => tags.sort((a: Tag, b: Tag) => this.tagCompare(a, b)));
            });
    }

    private getTagUniqueKey(tag: Tag): string {
        return `${tag.namespace}:${tag.key.toLowerCase()}`;
    }

    private getTagString(tag: Tag): string {
        return `${tag.resource.id}:${tag.namespace}:${tag.tag_organization_id}:${tag.key}:${tag.value}:${tag.key}`;
    }

    private isWriteTagsAllowed(array: { arrayId: string }): Observable<boolean> {
        return this.authorizationService.isAllowed('PURE1:write:tags', {
            type: <any>'APPLIANCE_PHONEBOOK_ID',
            id: array.arrayId,
        });
    }
}
